import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const PAGE_TITLE = "Success Stories"
const PAGE_NAME = "successStories"
const SuccessStoriesPage = props => {
  return (
    <Layout pageName={PAGE_NAME} headerTitle={PAGE_TITLE}>
      <SEO title={PAGE_TITLE} />
      <section id="about-us" className="container">
        <p>
          {" "}
          
        </p>
        <p>
          
        </p>
        <p>
          
        </p>
        <p>
          
        </p>
		<div className="row">
          <div className="col-sm-12">

			<p>
				AJVCD is involved in a large range of different projects. Below are listed some of the past projects and activities that AJVDC has worked on. 
				<br /><br />
			</p>

            <ul>
			  <li>
				We have succeed to demobilize more than 400 children (boys and girls) from armed groups, our fearless volunteers members are reaching out in armed group areas, to meet leaders of armed groups and tell them to release children from their groups, we are doing this in partnership with UN Mission, DDR program and FARDC (DRCongo force army).
				<br /><br />
			  </li>
			  <li>
				We have succeeded to install 3 community farms for growing food and creating jobs for youth and women, in Minova county, and bought 6 fish nets for fishing activities.
				<br /><br />
			  </li>
			  <li>
				There are others actions in Child Protection and Sexual violence programs that we have already done and still doing, in partnership WAR CHILD UK.
				<br /><br />
			  </li>
			  <li>
				We are also promoting the youth engagement in nature conservation and environment protection, by planting trees.
				<br /><br />
			  </li>
			</ul>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SuccessStoriesPage
